import request from '@/utils/request';

// #region 设备

/**
 * 设备列表
 */
export const getEquipmentList = (data) => {
  return request({
    url: '/api/equipment/list',
    method: 'get',
    params: data
  });
};

/**
 * 设备列表分类
 */
export const getEquipmentListType = () => {
  return request({
    url: '/api/equipment/equipmentTypeInfo',
    method: 'get'
  });
};

/**
 * 设备详情
 */
export const getEquipmentDetail = (id) => {
  return request({
    url: `/api/equipment/${id}`,
    method: 'get'
  });
};

/**
 * 编辑设备
 * @param {*} data
 * @returns
 */
export function putEditEquipment(data) {
  return request({
    url: '/api/equipment',
    method: 'put',
    data
  });
}

/**
 * 删除设备
 * @param {*} id 设备id
 * @returns
 */
export function deleteEquipmentById(id) {
  return request({
    url: `/api/equipment/${id}`,
    method: 'delete'
  });
}

/**
 * 获取设备列表数量
 * @returns
 */
export function getEquipmentListCount() {
  return request({
    url: '/api/equipment/count',
    method: 'get'
  });
}

// #endregion

// #region 采集器
/**
 * 获取采集器列表
 * @param {*} params
 * @returns
 */
export function getCollectorList(params) {
  return request({
    url: '/api/chip/list',
    method: 'get',
    params
  });
}

/**
 * 取采集器详情
 */
export const getCollectorDetail = (id) => {
  return request({
    url: `/api/chip/${id}`,
    method: 'get'
  });
};

/**
 * 删除采集器
 * @param {*} id chipId
 * @returns
 */
export function deleteCollectorById(id) {
  return request({
    url: `/api/chip/${id}`,
    method: 'delete'
  });
}

/**
 * 编辑采集器
 * @param {*} data
 */
export function putCollector(data) {
  return request({
    url: '/api/chip',
    method: 'put',
    data
  });
}

// #endregion 采集器

// #region 采集器单元

export function getCollectorUnitList(params) {
  return request({
    url: '/api/collectorUnit/list',
    method: 'get',
    params
  });
}

/**
 * 取采集单元详情
 */
export const getCollectorUnitDetail = (id) => {
  return request({
    url: `/api/collectorUnit/${id}`,
    method: 'get'
  });
};

/**
 * 获取采集单元数据信息
 */
export function getHistoryMetrics(params) {
  return request({
    url: '/api/collectorUnit/getHistoryMetrics',
    method: 'get',
    params
  });
}

/**
 * 更新采集单元信息报警阈值信息
 */
export function putThreshold(data) {
  return request({
    url: '/api/collectorUnit/updateThreshold',
    method: 'put',
    data
  });
}

/**
 * 获取报警记录列表
 * @param {*} params
 * @returns
 */
export function getRecordList(params) {
  return request({
    url: '/api/waringMsg/recordList',
    method: 'get',
    params
  });
}

/**
 * 标记已读或标记未读接口（传id和status）
 * @param {*} data
 * @returns
 */
export function putWaringMsgRead(data) {
  return request({
    url: '/api/waringMsg/read',
    method: 'put',
    data
  });
}

// #endregion 采集器单元

/**
 * 地区天气
 */
export const getAreaWeather = (params) => {
  return request({
    url: '/api/weather/info',
    method: 'get',
    params
  });
};

/**
 * 订单记录
 */
export const getValidOrderList = (data) => {
  return request({
    url: '/api/order/validOrderList',
    method: 'get',
    params: data
  });
};
