<template>
  <div class="index_wrap">
    <div class="left_box">
      <div class="left_top_avatar_box">
        <div class="avatar_detail_box">
          <div class="avatar_center_box">
            <div class="avatar_img">
              <img :src="avatarImgUrl" alt="" />
            </div>
            <div class="avatar_name">{{ info.nickname }}</div>
          </div>
        </div>
        <!--  -->
      </div>
      <div class="left_info_box">
        <ul class="left_info_ul">
          <li class="info_ul_item">
            <span>姓名</span>
            <span>{{ info.realname }}</span>
          </li>
          <li class="info_ul_item">
            <span>联系电话</span>
            <span>{{ info.phone }}</span>
          </li>
          <li class="info_ul_item">
            <span>公司名称</span>
            <span>{{ info.company }}</span>
          </li>
          <li class="info_ul_item">
            <span>行业-子行业</span>
            <span>{{ info.industry }}</span>
          </li>
          <li class="info_ul_item">
            <span>所在地址</span>
            <span>{{ info.address }} </span>
          </li>
          <li class="info_ul_item1">
            <span>领域</span>
            <span>{{ info.domainInfo }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="right_box">
      <div class="basic_equipmentList">
        <div class="basic_equipmentList_wrap">
          <div class="basic_equipmentList_head">
            <div class="basic_equipmentList_head_left">有效服务列表</div>
          </div>
          <div class="basic_equipmentList_wrap_main">
            <el-table
              :data="EquipmentList"
              stripe
              :row-style="{ height: '75px' }"
              style="width: 100%; height: 600px"
              :show-header="false"
            >
              <el-table-column label="Operate" min-width="25%">
                <template #default="{ row }">
                  <div class="scope">
                    <div class="right">
                      <div class="func">设备名称</div>
                      <div class="name">{{ row.equipmentName }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Operate" min-width="20%">
                <template #default="{ row }">
                  <div class="area">采集器名称</div>
                  <div class="area">
                    <div class="name">{{ row.collectorName }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" min-width="20%">
                <template #default="{ row }">
                  <div class="time">服务有效期</div>
                  <div class="time">{{ row.expirationTime }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" min-width="20%">
                <template #default="{ row }">
                  <div class="status">采集器CODE</div>
                  <div class="status">
                    {{ row.collectorCode }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" align="right" min-width="15%">
                <template #default="{ row }">
                  <div class="btns">
                    <div class="btn" @click="EquipmentDetail(row)">查看</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination_row">
              <div class="pagination_row_left">共{{ totalEquipmentNum }}条</div>
              <div class="pagination_row_right">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalEquipmentNum"
                  :page-size="PAGESIZE"
                  v-model:current-page="pageNum"
                  @current-change="getEquipmentListInfo"
                  class="pagination_item"
                >
                </el-pagination>
                <div class="pagination_row_input_wrap">
                  跳转到<el-input-number
                    class="pagination_row_input_box"
                    style="height: 30px"
                    v-model="pageInputNum"
                    :step="1"
                    :precision="0"
                    :min="1"
                    :max="maxPageNum"
                    size="small"
                  />页
                </div>
                <el-button
                  style="height: 30px"
                  class="pagination_row_btn"
                  size="small"
                  @click="handleJumpPage"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="EquipmentDialogVisiable"
      title="设备详情"
      width="40%"
      class="edit_equipment_dialog_box"
    >
      <!-- :before-close="handleClose" -->
      <div class="dialog_main">
        <el-form
          :model="EquipmentDataDetail"
          label-width="80px"
          label-position="left"
        >
          <el-form-item label="订单编号" :disabled="true" prop="name">
            <el-input
              v-model="EquipmentDataDetail.orderNo"
              :disabled="true"
              size="default"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细名称" prop="name">
            <el-input
              :disabled="true"
              v-model="EquipmentDataDetail.mealName"
              size="default"
              style="width: 50%"
            ></el-input>
          </el-form-item>

          <el-form-item label="支付金额" prop="name">
            <el-input
              :disabled="true"
              v-model="EquipmentDataDetail.payMoney"
              size="default"
              style="width: 20%"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <el-button @click="handleCancle" size="default">取消</el-button>
          <!-- <el-button type="primary" @click="editEquipment" size="default"
            >提交</el-button
          > -->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import { ref, getCurrentInstance, onMounted, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { getCompany } from '@/api/homePage.js';
import { getValidOrderList, getEquipmentList } from '@/api/equipment.js';
import { setItem, getItem, removeAllItem } from '@/utils/storage';
// import router from '@/router';
const baseURL = process.env.VUE_APP_BASE_API;
const PAGESIZE = 8; // 一页最多显示的数量

// 获取上下文proxy
// const { $api } = getCurrentInstance().appContext.config.globalProperties;

// 得到用户信息
const getInfo = ref();
const info = reactive({
  avatar: '', // 头像
  nickname: '', // 微信名
  realname: '', // 姓名
  phone: '', // 联系方式
  company: '', // 公司
  industry: '', // 子行业
  address: '', // 地址
  domainInfo: '' // 领域
});
getInfo.value = getItem('userInfo');
const avatarImgUrl = computed(() => {
  if (!info.avatar) return '';
  const regex = /^http/;
  if (regex.test(info.avatar)) return info.avatar;
  return baseURL + info.avatar;
});
if (getInfo.value == null) {
  removeAllItem();
  console.log('2222222222222222222222222222222222222', getItem('userInfo'));
} else {
  info.avatar = getInfo.value.avatar;
  info.nickname = getInfo.value.nickname;
  info.realname = getInfo.value.realname;
  info.phone = getInfo.value.phone;
  info.company = getInfo.value.company;
  info.industry = getInfo.value.industry;
  info.domainInfo = getInfo.value.domainInfo;
  if (getInfo.value.address) {
    info.address =
      JSON.parse(getInfo.value.address)[0].name +
      ' ' +
      JSON.parse(getInfo.value.address)[1].name +
      ' ' +
      JSON.parse(getInfo.value.address)[2].name;
  }
}

onMounted(() => {
  get();
});

// 输入框的页数
const pageInputNum = ref(1);
// 最大的页数
const maxPageNum = ref(1);

// 数据库中符合要求的数据的数量
const totalEquipmentNum = ref(0);
// 获取有效服务列表
const EquipmentList = ref([]);
// 页码
const pageNum = ref(1);
const pageSize = ref(8); // 每页条数
// 获取有效服务列表

const get = () => {
  const data = {
    pageNum: pageNum.value,
    pageSize: pageSize.value
  };
  console.log('123123', data);
  getValidOrderList(data).then((res) => {
    const { data } = res;
    console.log(data);
    const { list, total, size } = data;
    EquipmentList.value = list;
    totalEquipmentNum.value = total;
    EquipmentList.value = res.data.list;
    // pageNum.value = res.data.pageNum;
    maxPageNum.value = Math.ceil(total / size);
  });
};

// 点击页数跳转的 确认 按钮
function handleJumpPage() {
  pageNum.value = pageInputNum.value;
  getEquipmentListInfo();
}

function getEquipmentListInfo() {
  get();
}

// 设备详情
const EquipmentDialogVisiable = ref(false);
const EquipmentDataDetail = reactive({
  mealName: '',
  orderNo: '',
  payMoney: ''
});
// 点击查看
function EquipmentDetail(item) {
  EquipmentDialogVisiable.value = !EquipmentDialogVisiable.value;
  console.log('EquipmentDialogVisiable.value', item);
  EquipmentDataDetail.mealName = item.mealName;
  EquipmentDataDetail.orderNo = item.orderNo;
  EquipmentDataDetail.payMoney = item.payMoney;
}
// 点击取消
function handleCancle() {
  EquipmentDialogVisiable.value = false;
}
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.index_wrap {
  width: 98%;
  margin: 0 auto;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  .left_box {
    width: 30%;
    // background-color: green;
    .left_top_avatar_box {
      width: 100%;
      height: 160px;
      border-radius: 10px;
      background-color: #fff;
      .avatar_detail_box {
        width: 100px;
        height: 100%;
        margin: auto;
        .avatar_center_box {
          width: 100px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .avatar_img {
            width: 100px;
            height: 100px;
            background-color: #fff;
            overflow: hidden;
            border-radius: 50px;
          }
          .avatar_name {
            text-align: center;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .left_info_box {
      height: 515px;
      margin-top: 40px;
      border-radius: 10px;
      background-color: #fff;
      .left_info_ul {
        .info_ul_item {
          height: 85px;
          line-height: 85px;
          margin: 0 20px;
          border-bottom: 2px solid #f6f6f6;
          display: flex;
          justify-content: space-between;
          span {
            display: inline-block;

            font-size: 14px;
          }
        }
        .info_ul_item1 {
          height: 80px;
          line-height: 80px;
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          span {
            display: inline-block;
            font-size: 14px;
          }
        }
      }
    }
  }
  .right_box {
    width: 67%;
    height: 715px;
    border-radius: 10px;
    background-color: #fff;
    .basic_equipmentList {
      .basic_equipmentList_wrap {
        // height: 100%;
        padding: 14px;

        .basic_equipmentList_head {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .basic_equipmentList_head_left {
            font-size: 18px;
          }

          .basic_equipmentList_head_right {
            display: flex;
            height: 40px;
            align-items: center;

            .equipment_status_select {
              flex: 1.2;
              margin-right: 15px;

              //   :deep(.el-radio-button__inner) {
              //     height: 35px;
              //     line-height: 35px;
              //   }
            }

            .equipment_name_input {
              height: 32px;
              margin-right: 15px;
              :deep(.el-input__inner) {
                height: 32px;
                // line-height: 10px;
              }
            }

            .equipment_type_option {
              height: 32px;

              :deep(.el-input) {
                height: 32px;
                // line-height: 10px;
              }
            }

            .equipment_type_option,
            .equipment_name_input {
              flex: 1.2;
            }
          }
        }

        .basic_equipmentList_wrap_main {
          margin-top: 10px;
          height: 650px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          :deep(.scope) {
            display: flex;

            .img {
              width: 75px;
              height: 50px;
              margin-right: 10px;
              border-radius: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
              }
            }

            .name {
              color: rgba(0, 0, 0, 0.95);
              font-size: 14px;
            }
          }

          :deep(.btns) {
            display: flex;
            justify-content: center;
            .btn {
              margin: 0 8px;
              color: #1890ff;
              cursor: pointer;
            }

            .line {
              margin-top: 2px;
              width: 1px;
              height: 16px;
              border-left: 1px solid #dcdfe6;
            }
          }

          .pagination_row {
            margin-top: 10px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pagination_row_left {
              font-size: 13px;
              color: #606266;
            }

            .pagination_row_right {
              display: flex;
              font-size: 13px;
              align-items: center;

              .pagination_item {
                height: 30px;
                :deep(button) {
                  height: 30px;
                }
                :deep(ul li) {
                  height: 30px;
                }
              }
              .pagination_row_input_wrap {
                margin-left: 10px;
                .pagination_row_input_box {
                  margin-left: 10px;
                  margin-right: 10px;
                }
                // 这部分是可以自然设置number input部分样式(保留)
                // #region
                // .pageInput {
                //   &:deep(.el-input__inner) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__decrease) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__increase) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                // }
                // #endregion
              }

              .pagination_row_btn {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
